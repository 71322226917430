<template>
  <h1>BALANCE:</h1>
  <h2>{{ balanceEuro }}</h2>
  <h3>{{ balance }} ETH</h3>
  <p>
    <i>{{ restebalance }}% de 0.1 ETH</i>
  </p>
  <p style="text-align: right">
    <i>1 ETH = {{ euro }} EURO</i><br />
    <i>Il y a: {{ timeBeforRefreshHuman }}</i>
  </p>
  <hr />
  <h1>RIGS:</h1>
  <div v-for="(rig, index) in rigs" :key="index">
    <p>
      {{ rig.id }}: {{ rig.shares }} -
      <i>{{ unixtimeToDate(rig.lastShare) }}</i>
    </p>
  </div>

  <h1>Shares:</h1>
  <table>
    <thead>
      <tr>
        <th>Date</th>
        <th>Shares</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(share, index) in shares" :key="index">
        <td>{{ unixtimeToDate(share.date) }}</td>
        <td>{{ share.shares }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

export default {
  name: "App",
  data() {
    return {
      rigs: {},
      balance: 0,
      restebalance: 0,
      euro: 0,
      shares: [],
      balanceEuro: 0,
      countRefresh: 0,
      timeBeforRefresh: 0,
      timeBeforRefreshHuman: "",
    };
  },
  methods: {
    unixtimeToDate(unix) {
      return moment.unix(unix).utc(2).calendar();
    },
    getInfoMine() {
      axios
        .get(
          "https://api.nanopool.org/v1/eth/workers/0x9b3Da6Bff71f63c45979300620D5A5A83706cB66"
        )
        .then((response) => {
          let sharesFormatArrayForrigs = [];
          for (const key in response.data.data) {
            axios
              .get(
                `https://api.nanopool.org/v1/eth/shareratehistory/0x9b3Da6Bff71f63c45979300620D5A5A83706cB66/${response.data.data[key].id}`
              )
              .then((sharesResponse) => {
                let sharesFormat = {
                  ...response.data.data[key],
                  shares: sharesResponse.data.data[0].shares,
                };
                sharesFormatArrayForrigs.push(sharesFormat);
              });
          }
          this.rigs = sharesFormatArrayForrigs;
        });
      axios
        .get(
          "https://api.nanopool.org/v1/eth/balance_hashrate/0x9b3Da6Bff71f63c45979300620D5A5A83706cB66"
        )
        .then((response) => {
          this.balance = response.data.data.balance.toFixed(8);
          let restebalance = (this.balance / 0.1) * 100;

          this.restebalance = Math.round(restebalance * 100) / 100;
          axios
            .get(
              "https://api.nanopool.org/v1/eth/hashratechart/0x9b3Da6Bff71f63c45979300620D5A5A83706cB66"
            )
            .then((response) => {
              this.shares = response.data.data.slice(0, 5);
            });
          axios
            .get(
              "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=EUR"
            )
            .then((response) => {
              this.euro = response.data.EUR;
              this.balanceEuro = new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(this.balance * this.euro);
            });
        });
    },
  },
  mounted() {
    this.getInfoMine();
    setInterval(() => {
      if (this.timeBeforRefresh === 120) this.timeBeforRefresh = 0;
      this.timeBeforRefresh++;
      this.timeBeforRefreshHuman = moment(this.timeBeforRefresh * 1000).format(
        "mm:ss"
      );
    }, 1000);
    setInterval(() => {
      this.getInfoMine();
      this.countRefresh++;
    }, 120000);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
  margin-top: 60px;
  padding: 50px 10%;
}
table {
  width: 100%;
  border: 1px solid black;
}
th {
  text-align: left;
}
tr:nth-child(even) {
  background-color: #dfdfdf;
}
td {
  padding: 10px;
  text-align: left;
}
</style>
