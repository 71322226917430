import { createApp } from "vue";
import App from "./App.vue";

createApp(App).mount("#app");

// demander la permission de notifier

// fonction qui fait des notifications google chrome
function notif(title, message) {
  if (Notification.permission === "granted") {
    var notif = new Notification(title, {
      body: message,
      icon:
        "https://raw.githubusercontent.com/julien-boudry/Condorcet/master/app/assets/images/logo.png",
    });

    console.log(notif);
    notif.onclick = function() {
      window.focus();
      notif.close();
    };
    notif.onerror = function() {
      notif.close();
    };
    notif.onclose = function() {
      notif.close();
    };
  } else {
    Notification.requestPermission();
    notif(title, message);
  }
}

notif("test", "test");
